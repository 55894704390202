import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ButtonVnt from "../components/button-vnt"

const SoftwareFactory = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "Programming-pana.png" }) {
        base
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO title="Fabrica de software" />
      <div className="container page-wrapper">
        <div className="row">
          <div className="col-lg-6">
            <div className="service-img-wrapper">
              <Img
                fluid={data.file.childImageSharp.fluid}
                alt={data.file.base}
              />
            </div>
          </div>

          <div className="col-lg-6 col-xl-5 ml-xl-auto">
            <div className="special_description_content">
              <div className="section_heading">
                <h2 className="title-text">Software Factory.</h2>
                <div className={`mb-5 underline_shape`}></div>
              </div>

              <p className="paragpah-text">
                Apoyamos sus emprendimientos en todas las etapas del ciclo de
                vida del desarrollo de software (identificación de la necesidad,
                especificación, diseño, construcción, pruebas, puestas en
                producción y soporte).
              </p>
              <h2 className="title-text">¿Que Hacemos?</h2>
              <p className="paragpah-text">
                Centrados en la optimización de recursos, lo cual genera un
                ahorros de costos y aprovechamiento de la inversión, abordamos
                tus necesidades de negocio de manera integral desde el front-end
                y/o back-end. Nuestros especialistas en el ciclo de desarrollo
                de software son conocedores de las herramientas: Python, Java,
                Javascript, Oracle PL/SQL, Oracle ADF, Angular, Flutter, Oracle
                Database, PostgreSQL, MySQL, MariaDB, DB2, SQLServer y
                Firebase-Firestore.
              </p>
            </div>
            <div className="mt-4">
              <ButtonVnt
                linkTo="/#contact-us"
                textLabel="Contáctanos"
                buttonType="default"
              ></ButtonVnt>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default SoftwareFactory
